<template>
  <div>
    <h3 ref="ia">
      Gravitational Force
    </h3>
    <p>
      Gravitational force is an attractive force that acts between two bodies due to the virtue of their mass. Newton's law gives the magnitude of gravitational force
      acting between two bodies.
      $$F_{G} = \frac{G m_1 m_2}{r^2}$$
      where  \(m_1\) and \(m_2\) are the masses of the two bodies, and \(r\) is the distance between them (measured between their centers of mass).
      \(G\) is a universal constant called gravitation constant.
    </p>
    <h3 ref="ia">
      Earth's Gravity
    </h3>
    <p>
      The most commonly experienced form of gravitational force is the Earth's gravity. Earth's gravity causes the objects to fall when left free.
      For example, when you kick a ball from the top of a building, the ball falls to the ground due to the Earth's gravity.
      Earth's gravity is characterized by the acceleration that an object experiences while falling under the Earth's gravity.
      This acceleration is called acceleration due to gravity and is commonly denoted by the symbol \(g\). <br>

      Let's consider an object of mass \(m\) that is falling from a height towards the surface of the Earth.
      The gravitational force experienced by this object as it falls towards Earth is
      $$F_G = \frac{G m M}{R^2} = m \frac{G M}{R^2}  $$
      where \(M\) is the Earth's mass, and \(R\) is Earth's radius. Comparing with Newton's second law \(F = m a\), we
      obtain the acceleration due to gravity as
      $$a = \frac{G M}{R^2} = g$$
    </p>
    <h3 ref="ca">
      Motion of an Object Falling under Gravity
    </h3>
    <p>
      We will examine the motion of a ball kicked from the top of a building of height \(h\).
      Let's say the kicker kicks the ball horizontally at an initial speed of \(v\). Thus, the initial velocity of the ball is given as
      $$u_x(t=0) = v$$
      and
      $$u_y(t=0) = 0$$
      <ul style="list-style-type: square;">
        <li>
          <h5> Velocity during Flight</h5>
          The air resistance is assumed to be negligible. Then, the only force acting on the object is due to gravity, which acts in the downward direction (taken to be negative y-direction).

          Since there is no force acting on the object in the horizontal direction, the horizontal velocity of the object \(u_x\) remains constant at
          all times during the flight of the object.
          $$u_x (t) = u_x(0) = v$$
          <br>
          The vertical velocity of the object at \(t=0\) is given as
          $$u_y (t) = u_y(0) - g t = - g t$$
          where (-)ve sign implies that the y-component of velocity points in the downwards direction.
        </li>
        <li>
          <h5>Duration of Descent (t<sub>dc</sub>)</h5>
          According to the third equation of motion, the vertical distance travelled by the object is given by
          $$y(t) = y(0)-\frac{1}{2}g\ t^2 $$
          For the motion of the ball, \(y(0) = h\) and \(y(t) =0\). This gives the time of descent as:
          $$t_{dc} = \sqrt{\frac{2 h}{g}}$$
        </li>
        <li>
          <h5>Horizontal Distance Traveled (d)</h5>
          The total horizontal distance traveled can simply be calculated by multiplying the horizontal velocity with the total time of flight.
          $$d = v_0 \ t = v_0 \sqrt{\frac{2h}{g}}$$
        </li>
      </ul>
    </p><h3 ref="pg">
      MagicGraph | Motion of a Football Falling under Gravity
    </h3>
    <p>Through this MagicGraph, students will learn the motion of an object falling under the effect of Earth's gravity.</p>
    <ul style="list-style-type: square;">
      <li>
        <h5> Getting Started</h5>
        First, choose a height to kick from by moving the draggable control point.
        Then, tap on the kicker to kick the ball.
      </li>
      <li>
        <h5> To Explore</h5>
        The goal is to find a height such that the ball upon fall lands at the hand of the catcher who is located a distance of 4 m from the building.
      </li>
    </ul>
    <v-responsive
      :aspect-ratio="1"
    >
      <v-layout justify-center>
        <div id="jxgbox1" class="edliy-box-about" />
      </v-layout>
      <v-layout justify-center>
        <v-hover v-slot:default="{ hover }">
          <v-btn :color="hover ? '#757575' : '#2E2E2E' "
                 large
                 depressed
                 @click="$router.push({name: 'physics'})"
          >
            <v-img
              src="/assets/masswhite.svg"
              aspect-ratio="1"
              contain
            />
          </v-btn>
        </v-hover>
        <v-hover v-slot:default="{ hover }">
          <v-btn :color="hover ? '#757575' : '#2E2E2E' "
                 large
                 depressed
                 @click="$router.push({name: 'home'})"
          >
            <v-img
              src="/assets/edliy.svg"
              aspect-ratio="1"
              width="5px"
              contain
            />
          </v-btn>
        </v-hover>
      </v-layout>
    </v-responsive>
  </div>
</template>
<script>
import Boxes from './Boxes.js'
export default {
  name: 'ProjectileMotion',
  created: function () {
    // Store mutations
    let title = 'Forces in Mechanics: Gravity';
    this.$store.commit('navigation/changeTitle', title);
    this.$store.commit('navigation/changeMenu', title);
    let newTopics = [
      {title: 'Projectile Motion', img:'/assets/number-1.svg', action: () => this.goto('ia')},
      {title: 'Equation of Projectile Motion', img:'/assets/number-2.svg', action: () => this.goto('ca')},
      {title: 'PlayGraph: Trajectory of a Football',img:'/assets/touch.svg', action: () => this.goto('pg')},
    ];
    this.$store.commit('navigation/replaceTopics', newTopics);
    let newshowhome = false;
    this.$store.commit('navigation/toggleshowhome', newshowhome);
    let newEng =true;
    this.$store.commit('navigation/replaceEng', newEng);
  },
  mounted () {
    MathJax.Hub.Queue(["Typeset", MathJax.Hub]);
    Boxes.box1();
  },
  metaInfo() {
  return{ title: 'Projectile Motion',
          titleTemplate: '%s - Learn interactively',
          meta: [ {name: 'viewport', content: 'width=device-width, initial-scale=1'},
                  {name: 'description', content: 'Projectile Motion'}
                ]
        }
   },
}
</script>
<style lang="scss">
@import 'src/styles/edliy-box.scss';
@import 'src/styles/subtopic-menu.scss';
@import 'src/styles/edliy-box-about.scss';
@import 'src/styles/screen-sizes.scss';
</style>
