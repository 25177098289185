const Boxes = {
    box1: function () {
	  JXG.Options.board.minimizeReflow = 'none';	
      var brd2 = JXG.JSXGraph.initBoard('jxgbox1',{boundingbox: [-4, 14, 12, -2],keepaspectratio: true, axis:false, ticks:false, grid:false, pan:{enabled:false}, zoom:{enabled:false}, showCopyright:false, showNavigation:false});
      var resize = function () {
          brd2.resizeContainer(brd2.containerObj.clientWidth, brd2.containerObj.clientHeight, true);
          brd2.fullUpdate();
          };
      window.onresize = resize;
      brd2.options.layer['line'] =6;
      brd2.options.layer['segment'] =6;
	  brd2.options.layer['point'] =6;
      brd2.options.layer['polygon'] =2;
      brd2.options.layer['image'] =8;
      ////////////////////////////////////////////////////
	  brd2.create('text', [2, 13, '<b>Motion of a Football under Gravity</b>'], {fixed:true, fontSize:function(){return 32*brd2.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'});
	  //
	  var track =brd2.create('segment', [[-3.8,0], [-3.8, 16]], {visible:false});
	  brd2.create('segment', [[4,0], [4, -4]], {strokeColor:'white', strokeWidth:1, dash:1});
	  
	  brd2.create('segment', [[0,0], [0, -4]], {strokeColor:'white', strokeWidth:1, dash:1});
	  
	  brd2.create('segment', [[0,-1], [4, -1]], {strokeColor:'white', strokeWidth:1});
	  
	  brd2.create('text', [2, -1.5, '4 m'], {fixed:true, color:'white', fontSize:function(){return 12*brd2.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'});

	  
	  
	  var lev =brd2.create('glider', [-3.8, 6, track], {face:'square', size:4, name:'Height(Drag Me!)', fillColor:'yellow', strokeColor:'black', label:{fontSize:function(){return 12*brd2.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});
	  
	  brd2.create('text', [-1.5, function(){return lev.Y()+2}, 'v= 2 m/s'], {fixed:true, fontSize:function(){return 18*brd2.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'});
	   
	  brd2.create('polygon', [[-3.8, 0], [0, 0],[0, function(){return lev.Y();}],lev],{vertices:{visible:false, fixed:true}, withLines:false, fillColor:'orange', fillOpacity:1});
	  
	  brd2.create('polygon', [[-3.8, 0], [16, 0],[16, -6],[-3.8, -6]],{vertices:{visible:false, fixed:true}, withLines:false, fillColor:'green', fillOpacity:1});
	  //
	  var ball =brd2.create('point', [0, function(){return lev.Y();}], {name:'', size:0});
	  var play = brd2.create('image', ['/assets/kicker.svg', [-1.65, function(){return lev.Y()-0.45;}],[2,2]],{fixed:true, shadow:false});
	  //var foot = brd2.create('image', ['/assets/ball.svg', [function(){ball.X()-0.25}, function(){return ball.Y();}],[0.5,0.5]],{fixed:true, shadow:false});
	  //
	  //
	  var x =0; var y=6; var tt=0; var dt=0.01; var i=0; var j=0;
	  var run =function(){
	  if(i==0){
	  brd2.removeObject(ball);
	  ball = brd2.create('point', [0, lev.Y()], {name:'', size:1, trace:true, visible:function(){if(j<1){return false} else{return true}} });}
	  i =1;
	  if(y>0 && j>0){
		tt +=dt;
		x = 4.00*tt;
		y = lev.Y()-0.5*9.8*tt*tt;}
        ball.moveTo([x,y]);
		if(y>=0 && j>0){
		setTimeout(run, 0);}
		else{return;}
        }
	  play.on('down', function(){x=0; y=lev.Y(); tt=0; i=0; j=1; run();});
	  
	  lev.on('down', function(){x=0; y=lev.Y();j=0;});
	  
	  brd2.create('image', ['/assets/ball.svg', [function(){return x-0.22}, function(){return y}],[0.5,0.5]],{visible:function(){if(j<1){return false} else{return true}}});

   	  brd2.create('image', ['/assets/catcher.svg', [3.8, -0.85],[2,2]],{fixed:true, shadow:false});


	  //
	  brd2.create('text', [-3.8, function(){return 0.5*lev.Y();}, function(){return (lev.Y()).toFixed(1)+' m'}], {fixed:true, fontSize:function(){return 12*brd2.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'});
	  //
	  brd2.create('text', [function(){return x;},-0.5, function(){return (x).toFixed(1)+' m'}], {fixed:true, color:'white', fontSize:function(){return 12*brd2.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'});
      },
}
export default Boxes;
